const SET_AUTOSAVE_TIMEOUT_ID = 'timeout/SET_AUTOSAVE_TIMEOUT_ID';
const SET_AUTOSAVE_ENABLED = 'timeout/SET_AUTOSAVE_ENABLED';

const initialState = {
    autoSaveEnabled: true,
    autoSaveTimeoutId: null
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_AUTOSAVE_TIMEOUT_ID:
        return Object.assign({}, state, {
            autoSaveTimeoutId: action.id
        });
    case SET_AUTOSAVE_ENABLED:
        return Object.assign({}, state, {
            autoSaveEnabled: action.isEnabled
        });
    default:
        return state;
    }
};
const setAutoSaveTimeoutId = id => ({
    type: SET_AUTOSAVE_TIMEOUT_ID,
    id
});
const setAutoSaveEnabled = isEnabled => ({
    type: SET_AUTOSAVE_ENABLED,
    isEnabled
});

export {
    reducer as default,
    initialState as timeoutInitialState,
    setAutoSaveTimeoutId,
    setAutoSaveEnabled
};
