exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.bot-information-pane_stripe-button_3q8JW {\n  display: inline-block;\n  font-family: Orbitron;\n  font-size: 1rem;\n  -webkit-box-shadow: -10px 10px rgba(0,0,0,0.7);\n          box-shadow: -10px 10px rgba(0,0,0,0.7);\n  border: none;\n  cursor: pointer;\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n  padding: 10px;\n  margin-bottom: 10px;\n  margin-left: 40px;\n  width: 100px;\n  color: rgba(0,0,0,1);\n  background: #fff;\n  border: 2px solid rgba(0,0,0,1);\n}\n\n.bot-information-pane_selected_1IoT0 .bot-information-pane_stripe-button_3q8JW, .bot-information-pane_stripe-button_3q8JW:hover, .bot-information-pane_stripe-button_3q8JW:disabled {\n  -webkit-box-shadow: -3px 3px rgba(0,0,0,0.7);\n          box-shadow: -3px 3px rgba(0,0,0,0.7);\n}\n\n.bot-information-pane_simple-button_KcTys {\n  border: 1px solid rgba(0,0,0,1);\n  background-color: #ffffff;\n  color: rgba(0,0,0,1);\n  margin-right: 15px;\n  cursor: pointer;\n}\n\n.bot-information-pane_small-button_3t6Xm {\n  font-size: 75%;\n  border: 1px solid rgba(0,0,0,1);\n  background-color: #ffffff;\n  color: rgba(0,0,0,1);\n  cursor: pointer;\n}\n\n.bot-information-pane_small-button_3t6Xm:hover, .bot-information-pane_small-button_3t6Xm.bot-information-pane_active_3wOAj, .bot-information-pane_simple-button_KcTys:hover, .bot-information-pane_simple-button_KcTys.bot-information-pane_active_3wOAj {\n  background-color: rgba(0,0,0,1);\n  color: white;\n}\n\n.bot-information-pane_simple-button_KcTys:disabled, .bot-information-pane_small-button_3t6Xm:disabled {\n  background-color: #ddd;\n  border: none;\n  color: #333333;\n  cursor: default;\n}", ""]);

// exports
exports.locals = {
	"stripe-button": "bot-information-pane_stripe-button_3q8JW",
	"stripeButton": "bot-information-pane_stripe-button_3q8JW",
	"selected": "bot-information-pane_selected_1IoT0",
	"simple-button": "bot-information-pane_simple-button_KcTys",
	"simpleButton": "bot-information-pane_simple-button_KcTys",
	"small-button": "bot-information-pane_small-button_3t6Xm",
	"smallButton": "bot-information-pane_small-button_3t6Xm",
	"active": "bot-information-pane_active_3wOAj"
};