import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

const BlockCounts = props => {
    const {blockCounts = [], isFullScreen} = props;
    const tieBreakerWinner = () => {
        if (blockCounts.length === 2) {
            if (blockCounts[0].blockCount < blockCounts[1].blockCount) {
                return <span>{` ${blockCounts[0].playerName} wins a tie breaker`}</span>;
            } else if (blockCounts[1].blockCount < blockCounts[0].blockCount) {
                return <span>{` ${blockCounts[1].playerName} wins a tie breaker`}</span>;
            }
        }
        return (<></>);
    };
    return (isFullScreen ? <div style={{color: 'white'}}>
        {'Block Counts:'}
        {blockCounts.map(playerBlockCount => (
            <span key={playerBlockCount.playerName}>
                {` ${playerBlockCount.playerName} used ${playerBlockCount.blockCount} blocks,`}
            </span>
        ))}
        <span />
        {tieBreakerWinner()}
    </div> : <div style={{color: 'black'}}>
        {blockCounts[0] && blockCounts[0].blockCount && `${blockCounts[0].blockCount} Blocks Used`}
    </div>);
};

BlockCounts.propTypes = {
    blockCounts: PropTypes.arrayOf(PropTypes.shape({
        playerName: PropTypes.string.isRequired,
        blockCount: PropTypes.number.isRequired
    }).isRequired).isRequired,
    isFullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    blockCounts: state.scratchGui.projectState.blockCounts,
    isFullScreen: state.scratchGui.mode.isFullScreen
});

export default connect(mapStateToProps)(BlockCounts);
