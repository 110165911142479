import React from 'react';
import bindAll from 'lodash.bindall';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {manualUpdateProject, setProjectId} from '../../reducers/project-state';

import styles from './bot-information-pane.css';

class BotInformationPane extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'setProjectId',
            'setOpponentBotId',
            'handleSecretLinkCopyToClipboard',
            'handleShareLinkCopyToClipboard'
        ]);
        this.state = {
            opponentBotId: '',
            secretLinkCopySuccess: '',
            shareLinkCopySuccess: ''
        };
    }

    setProjectId (projectId) {
        return () => this.props.setProjectId(projectId);
    }

    setOpponentBotId () {
        return event => {
            this.setState({
                opponentBotId: event.target.value
            });
        };
    }

    handleSecretLinkCopyToClipboard () {
        navigator.clipboard.writeText(window.location.href);
        this.setState({
            secretLinkCopySuccess: 'Copied!',
            shareLinkCopySuccess: ''
        });
    }

    handleShareLinkCopyToClipboard (shareLink) {
        return () => {
            navigator.clipboard.writeText(shareLink);
            this.setState({
                secretLinkCopySuccess: '',
                shareLinkCopySuccess: 'Copied!'
            });
        };
    }

    render () {
        const queryObject = queryString.parse(window.location.hash.substring(1));
        const {player1: playerOneId} = queryObject;
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const needSave = this.props.canSave && this.props.projectChanged;
        const shareLink = `https://www.codechampionship.com/share/?${queryString.stringify({player2: playerOneId})}`;
        return (
            <div style={{position: 'relative', overflow: 'hidden', height: '100%', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'inline-block'}}>
                        <small>
                            {`Your Bot Number is:`}
                        </small>
                        <div style={{fontSize: '2rem'}}>{playerOneId}</div>
                    </div>
                    <button
                        className={styles.stripeButton}
                        onClick={this.props.onClickSave}
                    >
                        {saveNowMessage}
                    </button>
                </div>
                <hr />
                <div style={{fontSize: '1.5rem'}}>{`Check for Warnings`}</div>
                {needSave && (
                    <div>
                        {`Must save before reloading`}
                    </div>
                )}
                {this.props.ruleWarnings.length > 0 && (
                    <div>
                        <ul>
                            {this.props.ruleWarnings.map(warning => (
                                <li key={warning}>
                                    {warning}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div>
                    <button
                        className={styles.simpleButton}
                        onClick={this.setProjectId(`${window.location.hash.substring(1)}&cachebusting=${Math.random()}`)}
                        disabled={needSave}
                    >
                        {'Reload to Update Warnings'}
                    </button>
                    <button
                        className={styles.simpleButton}
                        onClick={this.setProjectId(`${window.location.hash.substring(1)}&cachebusting=${Math.random()}`)}
                        disabled={needSave}
                    >
                        {'Reload to Update Block Count'}
                    </button>
                </div>
                <hr />
                <div style={{fontSize: '1.5rem'}}>{`Opponents`}</div>
                {needSave && (
                    <div>
                        {`Must save before selecting a new opponent`}
                    </div>
                )}
                <div>
                    <button
                        className={styles.simpleButton}
                        onClick={this.setProjectId(window.location.hash.substring(1))}
                        disabled={needSave}
                    >
                        {'Default'}
                    </button>
                    <button
                        className={styles.simpleButton}
                        onClick={this.setProjectId(`${window.location.hash.substring(1)}&difficulty=hard`)}
                        disabled={needSave}
                    >
                        {'Hard'}
                    </button>
                    <input
                        placeholder="Another Bot Number"
                        onChange={this.setOpponentBotId()}
                        disabled={needSave}
                    />
                    <button
                        className={styles.simpleButton}
                        onClick={this.setProjectId(`${window.location.hash.substring(1)}&player2=${this.state.opponentBotId}`)}
                        disabled={needSave}
                    >
                        {'Go!'}
                    </button>
                </div>
                <div>
                    <hr />
                    <div>
                        {`Your secret link is:`}
                        <input value={window.location.href} />
                        <button
                            className={styles.simpleButton}
                            onClick={this.handleSecretLinkCopyToClipboard}
                        >
                            {'Copy'}
                        </button>
                        {this.state.secretLinkCopySuccess}
                        <br />
                        <small>
                            {`Copy and save this link somewhere to work on this project later.`}
                        </small>
                    </div>
                    <hr />
                    <div>
                        {`Your share link is:`}
                        <input value={shareLink} />
                        <button
                            className={styles.simpleButton}
                            onClick={this.handleShareLinkCopyToClipboard(shareLink)}
                        >
                            {'Copy'}
                        </button>
                        {this.state.shareLinkCopySuccess}
                        <br />
                        <small>
                            {`Share this link with your friends to see if they can beat your bot!`}
                        </small>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    projectChanged: state.scratchGui.projectChanged,
    ruleWarnings: state.scratchGui.projectState.ruleWarnings
});

const mapDispatchToProps = dispatch => ({
    onClickSave: () => dispatch(manualUpdateProject()),
    setProjectId: projectId => dispatch(setProjectId(projectId))
});

BotInformationPane.propTypes = {
    canSave: PropTypes.bool,
    onClickSave: PropTypes.func,
    projectChanged: PropTypes.bool,
    ruleWarnings: PropTypes.arrayOf(PropTypes.string),
    setProjectId: PropTypes.func
};

BotInformationPane.defaultProps = {
    ruleWarnings: []
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BotInformationPane);
